import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class socketService {

    private socket = io(environment.ENDPOINT);

    constructor(private httpClient: HttpClient) {

    }

    initSocket(e) {
        //console.log(e)
        let observable = new Observable(observer=> {
          this.socket.emit('init', e, (data) => {
            observer.next(data)
          });
          return () => {this.socket.disconnect();}
        });
        return observable;
    }


    listUser(){
      let observable = new Observable(observer=> {

        const user = JSON.parse(localStorage.getItem( 'user' ));
        // console.log(user);

        this.socket.emit('listUser', user['account'], (data) => {
          observer.next(data)
        });
        return () => {this.socket.disconnect();}
      });
      return observable;
    }

    closeSocket(e) {
        //console.log(e)
        e['logout'] = true;
        console.log(e)
        this.socket.emit('logout', e, (data) => {
            console.log(data)
        });
    }



}

import { Component, OnInit } from '@angular/core';
import { fncService } from './../../services/fcn.service';
import io from 'socket.io-client'
import countryFlagEmoji from "country-flag-emoji";

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';


import * as Chart from 'chart.js'
import { environment } from 'src/environments/environment';
import { continents } from '../../storage/ countriesByContinent'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {

  selectedTimeRange = '7';

  // startDate: string = '';
  // endDate: string = '';

  storage;
  user = {
    fullName: '',
    id: '',
    photo: ''
  };

  dashJobs = {
    blog: 0,
    kb: 0,
    Playlist: 0,
    chat: 0,
    jobAll: 0,
    jobsPending: 0,
    neurosciencegrrl: 0,
    neuroinsight: 0,
    depositValue: 0,
    hoursRequired: 0,
    hoursSpent: 0,
    hoursBalance: 0
  }

  chartsData = [0,0,0,0];

  result: string;
  visitas: any;
  porcentagem: number;
  url: any;
  title = 'angular8chartjs';
  canvas: any;
  ctx: any;
  email: string;
  usersOnline: any;
  loading: boolean;
  visitedLoading: boolean;
  users: number;
  shareBrain: number;
  shareNeuroGirl: number;
  shareNeuroInsight: number;
  role: number;

  usersByContinent = {
    countAmerica: 0,
    countryNorthAmerica: 0,
    countrySoutAmerica: 0,
    countryCentralAmerica: 0,
    countryAsia: 0,
    countryEuropa: 0,
    countryOceania: 0,
    countryAfrica: 0,
  }

  visitas_brainlatam: {};

 private httpOptions: any = {
    headers: new HttpHeaders({
        'x-access-token': localStorage.getItem( 'token' ),
        'Content-Type': 'application/json'
      })
  }
  constructor(private fnc:fncService, private httpClient:HttpClient){

    //add user
    this.storage = JSON.parse(window.localStorage.getItem('user'));
    this.user = this.storage;

    this.fnc.loading(false);
  }

  openLink(item) {
    this.fnc.openLinkShare(item, this.storage.email);
  }

  getJobs(){
      let observable = new Observable(observer=> {
          this.httpClient.get(environment.ENDPOINT+'api/dash/jobs').subscribe(( data:any ) => {
            observer.next(data)
          })
      });
      return observable;
  }

  getJobsPending(){
    let observable = new Observable(observer=> {
      this.httpClient.get(environment.ENDPOINT+'api/dash/jobs/pending').subscribe(( data:any ) => {
        observer.next(data)
      })
    });
    return observable;
  }

  getShares(){
    this.httpClient.get('https://www.brainlatam.com/api/share').subscribe(( data:any ) => this.shareBrain = data.length)
    this.httpClient.get('https://neurosciencegrrl.net/api/share').subscribe(( data:any ) => this.shareNeuroGirl = data.length)
    this.httpClient.get('https://neuroinsight.net/api/share').subscribe(( data:any ) => this.shareNeuroInsight = data.length)
    // a?
  }

  // getPayment(){
  //   let observable = new Observable(observer=> {
  //       this.httpClient.get(environment.ENDPOINT+'api/dash/payment').subscribe(( data:any ) => {
  //         observer.next(data)
  //       })
  //   });
  //   return observable;
  // }

  getBalance(){
    let observable = new Observable(observer=> {
        this.httpClient.get(environment.ENDPOINT+'api/dash/balance').subscribe(( data:any ) => {
          observer.next(data)
        })
    });
    return observable;
  }

  getCharts(){
    let observable = new Observable(observer=> {
        this.httpClient.get(environment.ENDPOINT+'api/user/dash').subscribe(( data:any ) => {
          observer.next(data)
        })
    });
    return observable;
  }

  // API
  getVisitas(){
    let observable = new Observable(observer=> {
        this.httpClient.get('https://www.brainlatam.com/api/visits').subscribe(( data:any ) => {
          observer.next(data)
        })

        // API LOCALHOST
        // this.httpClient.get('http://localhost:8000/api/visits').subscribe(( data:any ) => {
        //   observer.next(data)
        // })
    });
    return observable;
  }


  // API
  getVisitasDay(day){
    let observable = new Observable(observer=> {

        this.httpClient.get('https://www.brainlatam.com/api/visits?day=' + day).subscribe(( data:any ) => {
          observer.next(data)
        })
    });
    return observable;
  }

//ngAfterViewInit() {
gerarCharts() {
  this.canvas = document.getElementById('myChart');
  this.ctx = this.canvas.getContext('2d');
  let myChart = new Chart(this.ctx, {
    type: 'pie',
    data: {
        labels: ["Blue", "Green", "Orange", "red"],
        datasets: [{
            label: '# of Votes',
            data: this.chartsData,
            backgroundColor: [
                'rgba(54, 162, 235, 1)',
                'rgba(30, 100, 20, 1)',
                'rgba(255, 104, 0, 1)',
                'rgba(255, 1, 24, 1)'

            ],
            borderWidth: 1
        }]
    },
    options: {
      responsive: false,
      display:true
    }
  });
}

changeColorShare(number:Number){
  if(number <= 1000){
    return 'bg-danger';
  }
  if(number >= 1001 && number <= 2000){
    return 'bg-warning';
  }
  if(number >= 2001 && number <= 4000 ){
    return 'bg-success';
  }
  if(number > 4000){
    return 'bg-primary';
  }
}

incrementUserContinent(countryCode) {
  if (continents.arrayCountryAfrica.includes(countryCode)) {
    this.usersByContinent.countryAfrica += 1
    return
  }
  if (continents.arrayCountryAsia.includes(countryCode)) {
    this.usersByContinent.countryAsia += 1
    return
  }
  if (continents.arrayCountryCentralAmerica.includes(countryCode)) {
    this.usersByContinent.countryCentralAmerica += 1
    this.usersByContinent.countAmerica += 1
    return
  }
  if (continents.arrayCountryEuropa.includes(countryCode)) {
    this.usersByContinent.countryEuropa += 1
    return
  }
  if (continents.arrayCountryNorthAmerica.includes(countryCode)) {
    this.usersByContinent.countryNorthAmerica += 1
    this.usersByContinent.countAmerica += 1
    return
  }
  if (continents.arrayCountryOceania.includes(countryCode)) {
    this.usersByContinent.countryOceania += 1
    return
  }
  if (continents.arrayCountrySoutAmerica.includes(countryCode)) {
    this.usersByContinent.countrySoutAmerica += 1
    this.usersByContinent.countAmerica += 1
    return
  }
}

ngOnInit() {
  this.role = JSON.parse(localStorage.getItem('user')).roleId
  this.loading = true
  this.visitedLoading = true

  const socket = io("https://wss.brainlatam.com:2000/", {
    reconnectionDelayMax: 10000,
    path: '/socket/',
    cert: '/www/server/panel/vhost/cert/wss.brainlatam.com/fullchain.pem',
    key: '/www/server/panel/vhost/cert/wss.brainlatam.com/privkey.pem',
    timeout: 30000,
    jsonp: false,
    transports: ['websocket'],
    autoConnect: true,
  });

  socket.on('leadsOnline', usuariosOnline => {
    this.users = usuariosOnline.length
    this.usersByContinent = {
      countAmerica: 0,
      countryNorthAmerica: 0,
      countrySoutAmerica: 0,
      countryCentralAmerica: 0,
      countryAsia: 0,
      countryEuropa: 0,
      countryOceania: 0,
      countryAfrica: 0,
    }
    const usersOnline = usuariosOnline.map(user => {
      const ip = user.lead.leadIpInfo.query
      const countryName = user.lead.leadIpInfo.countryName
      const countryCode = user.lead.leadIpInfo.countryCode
      const regionName = user.lead.leadIpInfo.regionName
      const url = user.lead.leadsUrlsVisits[user.lead.leadsUrlsVisits.length - 1] ? user.lead.leadsUrlsVisits[user.lead.leadsUrlsVisits.length - 1].url : ''
      const emoji = countryFlagEmoji.get(countryCode).emoji;
      // contanto leads por continente
      this.incrementUserContinent(countryCode)
      return {ip, countryName, countryCode, url, emoji, regionName}
    })
    this.usersOnline = usersOnline
    this.loading = false
  });

  const user = JSON.parse(window.localStorage.getItem('user'));
  this.email = user.email;

  this.getShares()
  // this.getShare().subscribe((data: any) => this.share = data.length )
  this.getJobsPending().subscribe((data: any) => {
    const totalPending = data.reduce((acc,job) => acc + job.pending, 0)
    this.dashJobs.jobsPending = totalPending
  })

  this.getJobs().subscribe((data: any) => {
      data.forEach(element => {
          if(element.typeJob == 'Blog'){
              this.dashJobs.blog = element.number;
              this.dashJobs.jobAll += element.number;
          }
          if(element.typeJob == 'Knowledge Base'){
              this.dashJobs.kb = element.number;
              this.dashJobs.jobAll += element.number;
          }
          if(element.typeJob == 'Playlist'){
              this.dashJobs.Playlist = element.number;
              this.dashJobs.jobAll += element.number;
          }
          if(element.typeJob == 'Neurosciencegrrl'){
            this.dashJobs.neurosciencegrrl = element.number;
            this.dashJobs.jobAll += element.number;
          }
          if(element.typeJob == 'Neuroinsight'){
            this.dashJobs.neuroinsight = element.number;
            this.dashJobs.jobAll += element.number;
          }
          if(element.typeJob == 'Chat'){
            this.dashJobs.chat = element.number;
            this.dashJobs.jobAll += element.number;
          }
    });

  })

  this.getCharts().subscribe((data: any) => {

    data.forEach(element => {
        if(element['CG'] != 0){
            this.chartsData[1] = this.chartsData[1]+1;
        }

        if(element['CG'] == 0 && element['CO'] != 0){
          this.chartsData[2] = this.chartsData[2]+1;
        }

        if(element['CG'] == 0 && element['CO'] == 0){
          this.chartsData[3] = this.chartsData[3]+1;
        }
    });

    this.gerarCharts();

  })

  // this.getPayment().subscribe((data: any) => {
  //   this.dashJobs.payment = data[0];
  // })

  this.getBalance().subscribe((data: any) => {
    this.dashJobs.depositValue = data.depositValue;
    this.dashJobs.hoursSpent = data.hoursSpent;
    this.dashJobs.hoursRequired = data.hoursRequired;
    this.dashJobs.hoursBalance = data.hoursBalance;
  })

  this.getVisitas().subscribe((data: any)  => {
    let contagemTotal = data.result.reduce((acc, url) => {
      return  acc + Number(url.visitas);
    }, 0);

    this.visitas_brainlatam = data.result.map(url => ({
        country_code: url.country_code,
        title: url.title,
        url: url.url,
        visitas: url.visitas,
        visitasPorcentagem: Number(Number(url.visitas)*100/contagemTotal).toFixed(2),
        width: Number(url.visitas)*100/contagemTotal + '%'
    }));
    this.visitedLoading = false
  });
}

// Old Custom Date
/* filterDataByTimeRangeAndDates(data: any) {
  switch (this.selectedTimeRange) {
    // case 'custom':
    //   if (this.startDate && this.endDate) {
    //     let startDate = new Date(this.startDate);
    //     let endDate = new Date(this.endDate);


    //     const filteredData = data.filter((entry: any) => {
    //       let entryDate = new Date(entry.atCreated.split(" ")[0]);

    //       return entryDate >= startDate && entryDate <= endDate;
    //     });

    //     return filteredData;
    //   }
    //   break;

    case '1':
      const today = new Date();
      let todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

      let filteredData = data.filter((entry: any) => {
        let entryDate = new Date(entry.atCreated.split(" ")[0]); // Extrai apenas a data
        return entryDate.getTime() === todayDate.getTime();
      });

      return filteredData;

    case '7':
      const todayWeek = new Date();
      let startOfWeek = new Date(todayWeek);
      startOfWeek.setDate(todayWeek.getDate() - todayWeek.getDay());

      let filteredDataWeek = data.filter((entry: any) => {
        let entryDate = new Date(entry.atCreated.split(" ")[0]);
        return entryDate >= startOfWeek;
      });

      return filteredDataWeek;

    // case '15': // 15 Days
    //   const todayMonth = new Date();
    //   let startOfMonth = new Date(todayMonth.getFullYear(), todayMonth.getMonth(), 1);

    //   let filteredDataMonth = data.filter((entry: any) => {
    //     let entryDate = new Date(entry.atCreated.split(" ")[0]);
    //     return entryDate >= startOfMonth;
    //   });
    //   return filteredDataMonth;

    case '15':
      const today15Days = new Date();
      today15Days.setDate(today15Days.getDate() - 15);

      let filteredData15Days = data.filter((entry: any) => {
        let entryDate = new Date(entry.atCreated.split(" ")[0]);

        return entryDate >= today15Days;
      });

      return filteredData15Days;

    case '40':
      return data;
  }
} */

filterDataByTimeRangeAndDates(data: any) {

  const filteredData = [];

  data.forEach((entry: any) => {

    const entryDate = new Date(entry.atCreated.split(" ")[0]);
    const currentDate = new Date();
    const timeDifference = Math.abs(currentDate.getTime() - entryDate.getTime());
    const differenceInDays = Math.floor(timeDifference / (1000 * 3600 * 24));

    switch (this.selectedTimeRange) {
      case '1': // Today
        if (differenceInDays === 0) {
          filteredData.push(entry);
        }
        break;
      case '2': // Today
        if (differenceInDays <= 1) {
          filteredData.push(entry);
        }
        break;
      case '3': // Today
        if (differenceInDays <= 2) {
          filteredData.push(entry);
        }
        break;
      case '4': // Today
        if (differenceInDays  <= 3) {
          filteredData.push(entry);
        }
        break;
      case '5': // Today
        if (differenceInDays  <= 4) {
          filteredData.push(entry);
        }
        break;
      case '6': // Today
        if (differenceInDays <= 5) {
          filteredData.push(entry);
        }
        break;

      case '7': // This Week
        if (differenceInDays <= 6) {
          filteredData.push(entry);
        }
        break;

      case '15': // 15 Days
        if (differenceInDays <= 15) {
          filteredData.push(entry);
        }
        break;

      case '30': // 15 Days
        if (differenceInDays <= 30) {
          filteredData.push(entry);
        }
        break;

      case '40': // 40 Days
        filteredData.push(entry);
        break;
    }
  });

  return filteredData;
}

updateVisitas() {
  this.visitedLoading = true;

  this.getVisitas().subscribe((data: any) => {

    let filteredData = this.filterDataByTimeRangeAndDates(data.result);
    let contagemTotal = filteredData.reduce((acc, url) => acc + Number(url.visitas), 0);

    // Calculate and map the data
    this.visitas_brainlatam = filteredData.map(url => ({
      country_code: url.country_code,
      title: url.title,
      url: url.url,
      visitas: url.visitas,
      visitasPorcentagem: Number((Number(url.visitas) * 100 / contagemTotal).toFixed(2)),
      width: (Number(url.visitas) * 100 / contagemTotal) + '%'
    }));

    this.visitedLoading = false;
  });

}

 setTimeRangeChange() {

  this.visitedLoading = true;
  this.getVisitasDay(this.selectedTimeRange).subscribe((data:any) => {

    if(data){

      let filteredData = this.filterDataByTimeRangeAndDates(data.result);
      let contagemTotal = filteredData.reduce((acc, url) => acc + Number(url.visitas), 0);

      // Calculate and map the data

      this.visitas_brainlatam = data['result'].map(url => ({
        country_code: url.country_code,
        title: url.title,
        url: url.url,
        visitas: url.visitas,
        visitasPorcentagem: Number(url.visitasPorcentagem).toFixed(2),
        width: Number(url.visitasPorcentagem) + '%'
      }));

      this.visitedLoading = false;
    }

  })




}

onTimeRangeChange() {
  this.updateVisitas();
  this.visitedLoading = true;
}

// custom date update
/* onDateChange() {
  this.updateVisitas();
} */

}



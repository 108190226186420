import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class NavigatorCoordsService {

  constructor(private httpClient: HttpClient) {
    
  }
  
  public getInfoUser() {
    const observable = new Observable( observer => {
      observer.next( this.httpClient.get('http://ip-api.com/json') )
    });
    return observable;
  } 

  getNavigatorCoords() {

    const check = window.localStorage.getItem( 'info' );

    let info = {
      language: navigator.language,
      platform: navigator.platform,
      vendor: navigator.vendor,
      userAgent:  navigator.userAgent,
      appVersion: navigator.appVersion,
      firstAccess: Date.now(),
      screenWidht: window.screen.width,
      screenHeight: window.screen.height
    }

    if( !check ){
      if ( navigator.geolocation ) {
        navigator.geolocation.getCurrentPosition(function( res ){
          info['latitude'] = res.coords.latitude;
          info['longitude'] = res.coords.longitude;
        });
      }

      this.httpClient.get('http://ip-api.com/json').subscribe(function( response:any ){
        info['city'] = response.city;
        info['country'] = response.country;
        info['countryCode'] = response.countryCode;
        info['ip'] = response.query;
        info['region'] = response.region;
        info['timezone'] = response.timezone;
        info['latitude'] = (info['latitude']) ? info['latitude'] : response.lat;
        info['longitude'] = (info['longitude']) ? info['longitude'] : response.lon;  

        window.localStorage.setItem('info', JSON.stringify( info ));
        
      }, function( erro ){
        window.localStorage.setItem('info', JSON.stringify( info ));        
      });

    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private httpOptions: any = {
    headers: new HttpHeaders({
        'x-access-token': localStorage.getItem( 'token' ),
        'Content-Type': 'application/json'
      })
  }

  constructor(private router: Router, private httpClient: HttpClient) {   
  
  }

  getRoles(){
    let observable = new Observable(observer=>{
        this.httpClient.get( environment.ENDPOINT + 'roles',  this.httpOptions).subscribe(( data:any ) => {
          observer.next(data)
        }) 
    });
    return observable;
  }
  
  getSaldo(id: number){
    let observable = new Observable(observer=>{
        this.httpClient.get( environment.ENDPOINT + 'saldo/' + id,  this.httpOptions).subscribe(( data:any ) => {
          observer.next(data)
        }) 
    });
    return observable;
  }

  //responsavel para setar a cor no fluxograma
  setColorUsers(obj){
    if(obj['role'] == 'Administration' || obj['CB'] != 0){
      return 'AD';
    } else {
      if(obj['CG'] != 0){ 
        return 'CG';
      } 
      if(obj['CO'] != 0){ 
        return 'CO';
      }
      return 'CR';
    } 
  }

  getFluxograma(){
    let observable = new Observable(observer=>{
        this.httpClient.get( environment.ENDPOINT + 'fluxograma',  this.httpOptions).subscribe(( data:any ) => {

            let aUser = [];
            
            // console.log(data)
            for(let i in data){
              let info = data[i];
              let city = (info.city) ? ' | ' + info.city : '';
              let share = (info.share != undefined) ? info.share : 0;
              aUser[i] = {
                  id: info.id,
                  //img: environment.ENDPOINT+'public/upload/'+info.id+'.profile.jpg',
                  img: environment.ENDPOINT+'public/upload/'+info.img,
                  pid: info.userId,
                  name: info.fullName,
                  title: info.role + city + ' | '+share,
                  tags: [this.setColorUsers(info)]
              }
            }

            observer.next(aUser);

        }) 
    });
    return observable;
  }
  
  getUsers(filters){
    let observable = new Observable(observer=>{

        let headers = this.httpOptions.headers;        
        let params = new HttpParams();
        
        if(filters.search){
          params = params.append('search', filters.search);
        }

        if(filters.roles){
          params = params.append('roles', filters.roles);
        }   

        if(filters.filter){
          params = params.append('filter', filters.filter);
        }

        this.httpClient.get( environment.ENDPOINT + 'user?',  { headers , params } ).subscribe(( data:any ) => {
          observer.next(data)
        }) 
    });
    
    return observable;
  }

  getUsersAll(){
    let observable = new Observable(observer=>{
        this.httpClient.get( environment.ENDPOINT + 'user',  this.httpOptions).subscribe(( data:any ) => {
          observer.next(data)
        }) 
    });
    return observable;
  }

  getUsersMentors(id: number, city: String){
    let observable = new Observable(observer=>{

        let headers = this.httpOptions.headers;        

        this.httpClient.get( environment.ENDPOINT + 'mentorsUp/' + id + '/' + city,  { headers } ).subscribe(( data:any ) => {
          observer.next(data)
        }) 
    });
    
    return observable;
  }

  getUser(id: number){
    let observable = new Observable(observer=> {
        this.httpClient.get( environment.ENDPOINT + 'user/' + id,  this.httpOptions).subscribe(( data:any ) => {
          observer.next(data)
        }) 
    });
    return observable;
  }

  inUp(dados){    
    let observable = new Observable(observer=> {
      const url = environment.ENDPOINT + 'user';
      if(!dados['id']){
        this.httpClient.post( url, dados, this.httpOptions ).subscribe(( data:any ) => {
          observer.next(data);
        });
      } else {
        this.httpClient.put(url + '/' + dados.id, dados, this.httpOptions ).subscribe(( data:any ) => {
          observer.next(data);
        });
      }
    });
    return observable;
  }    

  redirect() {
    this.router.navigate(['/users']);
  }
}

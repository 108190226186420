import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { socketService } from './../services/socket.service';
import { fncService } from './../services/fcn.service';

import * as moment from 'moment';
//import { from } from 'rxjs';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})

export class AdminComponent implements OnInit {

  user = {
    fullName: '',
    id: '',
    photo: ''
  };

  mobile = false;
  storage;

  constructor(private router: Router, private fnc: fncService, private socket: socketService) {

    this.storage = JSON.parse(window.localStorage.getItem('user'));

    this.storage['hour'] = moment().format('HH:mm:ss');
    this.storage['type'] = 'user';
    this.storage['account'] = 'brainlatam';

    this.storage['info'] = JSON.parse(window.localStorage.getItem('info'));

    //add user 
    this.user = this.storage;

    fnc.loading(false);
    // console.log("TESTE");


  }

  ngOnInit() {
    this.mobile = this.fnc.mobileTrue();
    // console.log(this.user)
    this.socket.initSocket(this.user).subscribe((data: any[]) => {
      // console.log(data)
      //this.users = data['brain'];
    });
  }


  logout() {
    Swal.fire({
      title: 'Atenção?',
      text: 'Deseja realmente sair?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.value) {

        //close connection chat
        this.socket.closeSocket(this.user);

        //remove client
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        this.router.navigate(['/login']);

      }
    });
  }

  openLink(item) {
    let token = window.localStorage.getItem('token');
    if (item == 'brainlatam') {
      window.open("https://www.brainlatam.com/admin/login?token=" + token, '_blank');
    }
    if (item == 'brainsupport') {
      window.open("https://www.brainsupport.com.br/admin/login?token=" + token, '_blank');
    }
    if (item == 'chat') {
      window.open("https://chat.brainlatam.com/#/token/" + token, '_blank');
    }
    if (item == 'grrl' && this.storage.gender == 'F') {
      window.open("https://neurosciencegrrl.net?token=" + token, '_blank');
    }
    if (item == 'neuroinsight') {
      window.open("https://neuroinsight.net?token=" + token, '_blank');
    }
    if (item == 'fastcreate') {
      window.open("https://canvas.brainlatam.com?token=" + token, '_blank');
    }
    if (item == 'question') {
      window.open("https://question.brainlatam.com/dashboard/auth?token=" + token, '_blank');
    }
    if (item == 'theneurosoft') {
      window.open("https://theneurosoft.net?token=" + token, '_blank');
    }
  }

  avatar(img) {
    return this.fnc.getAvatarUrl(img);
  }

  // testeRato() {
  //   window.addEventListener("load", () => { alert("carregou") });
  //   const sidebar = document.getElementById("#accordionSidebar");
  //   if (sidebar) {
  //     sidebar.style.display = "none";
  //   }
  //   console.log(sidebar);
  //   alert("RATO");
  //   alert(sidebar)
  // }
}

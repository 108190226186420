import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from './services/auth-guard.service';
import { AdminComponent } from './admin/admin.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './admin/profile/profile.component';


const routes: Routes = [
  { path: '', component: AdminComponent, canActivate : [AuthGuardService],
    children: [
      { path: '', component: DashboardComponent, canActivate : [AuthGuardService] },
      { path: 'users', loadChildren: './admin/user/user.module#UserModule', canActivate : [AuthGuardService]},
      { path: 'timesheet/job', loadChildren: './admin/timesheet/job/job.module#JobModule', canActivate : [AuthGuardService]},
      { path: 'timesheet/payment', loadChildren: './admin/timesheet/payment/payment.module#PaymentModule', canActivate : [AuthGuardService] },
      { path: 'timesheet/balance', loadChildren: './admin/timesheet/balance/balance.module#BalanceModule', canActivate : [AuthGuardService] },
      { path: 'modules/fluxograma', loadChildren: './admin/modules/fluxograma/fluxograma.module#FluxogramaModule', canActivate : [AuthGuardService] },
      { path: 'modules/powerbi', loadChildren: './admin/modules/powerbi/powerbi.module#PowerbiModule', canActivate : [AuthGuardService] },
      { path: 'profile', component: ProfileComponent, canActivate : [AuthGuardService] },
      { path: 'certificate', loadChildren: './admin/certificate/certificate.module#CertificateModule', canActivate : [AuthGuardService]}
    ]
  }, 
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LoginComponent },
  { path: 'card', loadChildren: './card/card.module#CardModule'},
  { path: 'chat', loadChildren: './chat/chat.module#ChatModule', canActivate : [AuthGuardService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

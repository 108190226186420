import { Component } from '@angular/core';
import { fncService } from './services/fcn.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'crm';

  constructor(private fnc: fncService) {
    fnc.loading(true);
  }

  ngOnInit() {
    //console.log(this.user);
  }

}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NavigatorCoordsService } from '../services/navigator-coords.service';
import { fncService } from './../services/fcn.service';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  alertType = 'alert-danger';
  msg;
  public fGroup: FormGroup;

  constructor( private http: HttpClient, private fb: FormBuilder, private coords: NavigatorCoordsService, private fnc:fncService, private router: Router) {
    this.fGroup = this.fb.group({
      'email': ['', Validators.compose([
        Validators.required,
        Validators.email
      ])],
      'password': ['', Validators.compose([
        Validators.required,
        Validators.minLength(3)
      ])]
    });
  }

  ngOnInit() {
    this.coords.getNavigatorCoords();
    this.fnc.loading(false);
  }

  submitForm() {
    const credentials = this.fGroup.value;

    this.http.post( environment.ENDPOINT + 'login', credentials, {} ).subscribe(( data:any ) => {
      
      if(data.auth === true) {
        this.alertType = 'alert-success';
        this.msg = 'Login efetuado com sucesso.';
   
        window.localStorage.setItem('token', data.token);
        window.localStorage.setItem('user', JSON.stringify({'account': 'brainlatam', 'id': data.user.id, 'uuid': data.user.uuid, 'fullName': data.user.fullName, 'gender': data.user.gender, 'roleId': data.user.roleId, 'email': data.user.email, 'photo': data.user.img}));
        this.router.navigate(['/']);
        
      } else {
        this.msg = data.error;
      }
    });

  }
}

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { fncService } from './../../services/fcn.service';
import { UserService } from './../user/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  alertType = 'alert-danger';
  msg: string;
  id: Number = 0;
  listUser = [{ id: "", fullName: "No mentor" }];

  columns = ['id','fullName', 'password', 'valueHour', 'email', 'emailSecond', 'skype', 'phone', 'twitter', 'roleId', 'country', 'city', 'additionals', 'photo', 'eventImage', 'userId', 'interestArea', 'university', 'gender'];
  
  interestArea = [
    "EEG NIRS EyeTracking",
    "Neuroscience of Consciousness",
    "Translational Education",
    "Behavioral Research",
    "Games & Mixed Reality"
  ];
  

  user = {};
  data: object = {
    'balance':'00:00:00'
  };
  fControl: FormControl;
  userForm: any;

  constructor(private fnc: fncService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private userService:UserService) {
    this.createForm();
  }

  avatar(id, photo){
    return this.fnc.getAvatar(id, photo);
  }

  onFileChange(event, campo) {
    this.fnc.loading(true);
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.userForm.get(campo).setValue({
          filename: file.name,
          filetype: file.type,
          //value: reader.result.split(',')[1]
          value: reader.result
        })
        this.fnc.loading(false);
      };
    }
  }

  valids(campo){
    return this.fnc.valids(this.userForm, campo);
  }

  loadMentor(roleId, city) {
    if (roleId != 0) {
      this.userService
        .getUsersMentors(roleId, city)
        .subscribe((data: any[]) => {
          this.listUser = data;
        });
    }
  }

  onOptionsSelected(roleId, city) {
    this.loadMentor(roleId, city);
  }


  createForm() {
 
    const fControl = {};
    for (let c in this.columns) {
      const value = (this.data) ? this.data[this.columns[c]] : ''; 
      fControl[this.columns[c]] = new FormControl(value);
    }

    this.userForm = this.fb.group(fControl);

    this.userForm.controls["fullName"].setValidators([Validators.required, Validators.minLength(3)]);
    this.userForm.controls["password"].setValidators([Validators.minLength(4), Validators.maxLength(9)]);
    this.userForm.controls["email"].setValidators([Validators.required, Validators.email]);
    this.userForm.controls["emailSecond"].setValidators([Validators.required, Validators.email]);
    this.userForm.controls["twitter"].setValidators([Validators.required]);
    this.userForm.controls["city"].setValidators([Validators.required]);
    this.userForm.controls["university"].setValidators([Validators.required]);
  }

  submitForm() {
    if (this.userForm.valid) {
      this.fnc.loading(true);
      this.userService.inUp(this.userForm.value).subscribe((data: any)=>{ 
        try {
          if(data.type){
            this.alertType = 'alert-success';
            this.msg = data.msg;
           } else {
            this.msg = data.errors[0].msg;
           }
        } catch (error) {
          this.msg = 'error.';
        }
        this.fnc.loading(false);
      }); 
    }
  }

  ngOnInit() {

    this.user = JSON.parse(window.localStorage.getItem('user'));

    this.userService.getUser(this.user['uuid']).subscribe((data: any)=>{        
      this.data = data;
      this.data['password'] = '';
    
      this.loadMentor(data.roleId, data.city);

      this.userService.getSaldo(this.user['id']).subscribe((data: any)=>{        
        this.data['balance'] = (data['hoursBalance'] == 'null') ? 0 : data['hoursBalance'];
        
        this.fnc.loading(false);
        this.createForm();

      });

      
    });

  }

}

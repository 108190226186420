import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";

import { environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class fncService {

    uri = environment.ENDPOINT;

    constructor(private router: Router, private spinner: NgxSpinnerService) {

    }

    loading(show) {
        if (show == true) {
            this.spinner.show();
        } else {
            this.spinner.hide();
            if (window.innerWidth <= 468) { // 768px portrait
                $('#sidebarToggleTop').trigger("click");
            }
        }
    }

    mobileTrue() {
        if (window.innerWidth <= 468) { // 768px portrait
            return true;
        }
        return false;
    }

    restrict() {
        const user = JSON.parse(localStorage.getItem('user'));

        if (user['roleId'] != 1 && user['roleId'] != 2) {

            Swal.fire({
                title: 'Attention?',
                text: 'Access denied',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Close'
            })

            this.router.navigate(['/']);
            this.loading(false);
        }
    }

    getAvatarUrl(img) {
        return this.uri + 'public/upload/' + img;
    }

    getAvatar(id, base64) {
        const imageUrl = this.uri + 'public/upload/' + id + '.profile.jpg';
        if (base64 != undefined && base64 != '') {
            return imageUrl;
        } else {
            return this.uri + 'public/upload/0.profile.jpg';
        }
    }

    valids(forms, campo) {
        if (forms.get(campo).invalid && (forms.get(campo).dirty || forms.get(campo).touched)) {
            return 'is-invalid';
        } else {
            if (campo == 'password' && forms.get(campo).value == null) {
                return '';
            }
            return (forms.get(campo).status == 'VALID') ? 'is-valid' : '';
        }
    }

    mountDataAtual() {
        let dNow = new Date();
        let mm = ((dNow.getMonth() + 1) < 10) ? '0' + (dNow.getMonth() + 1) : (dNow.getMonth() + 1);
        let dd = (dNow.getDate() < 10) ? '0' + dNow.getDate() : (dNow.getMonth() + 1);
        let localdate = dNow.getFullYear() + '-' + mm + '-' + dd;
        return localdate;
    }

    mountDate(dias, operador) {

        let time = new Date();
        let outraData = new Date();

        if (operador == false) {
            return formatDate(outraData.setDate(time.getDate() - dias), 'yyyy-MM-dd', 'en');
        }
        return formatDate(outraData.setDate(time.getDate() + dias), 'yyyy-MM-dd', 'en');
    }


    openLinkShare(item, email) {
        let token = window.localStorage.getItem('token');
        if (item == 'brainlatam') {
            window.open("https://www.brainlatam.com/?user=" + email, '_blank');
        }
        if (item == 'brainsupport') {
            window.open("https://www.brainsupport.com.br/?user=" + email, '_blank');
        }
        if (item == 'grrl') {
            window.open("https://neurosciencegrrl.net/?user=" + email, '_blank');
        }
        if (item == 'neuroinsight') {
            window.open("https://neuroinsight.net/?user=" + email, '_blank');
        }
        if (item == 'theneurosoft') {
            window.open("https://theneurosoft.net/?user=" + email, '_blank');
        }
    }
}
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { fncService } from './fcn.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor( private router: Router, private fnc:fncService ) {
    
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token = localStorage.getItem( 'token' );
    this.fnc.loading(true);
    
    if(!token) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }

}
